import CommunityMember from '../pages/CommunityMember'
import Contact from '../pages/Contact'
import Home from '../pages/Home'
import OurMission from '../pages/OurMission'
import PolicyPrivacy from '../pages/PolicyPrivacy'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: <Home />,
  },
  {
    path: '/our-mission',
    name: 'Nosotros',
    component: <OurMission />,
  },
  {
    path: '/community-member',
    name: 'Community Member',
    component: <CommunityMember />,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: <Contact />,
  }, {
    path: '/politica-de-datos',
    name: 'Politica de datos',
    component: <PolicyPrivacy />,
    showInNavbar: true
  }
]

export { routes } 