import camiloSuarez from '../assets/team/camilo_suarez.png'
import fabianDelgado from '../assets/team/fabian_delgado.jpeg'
import alejandroCelis from '../assets/team/alejandro_celis.jpeg'
import estebanAragon from '../assets/team/esteban_aragon.png'
import fitzgeraldCastano from '../assets/team/fitzgerald_castano.png'
import felipeMendoza from '../assets/team/felipe_mendoza.jpeg'
import cesarLopez from '../assets/team/cesar_lopez.jpeg'
import juanRestrepo from '../assets/team/juan_restrepo.jpeg'
import brayangiraldo from '../assets/team/brayan_giraldo.jpeg'

export const TEAM = [
    {
        id: 1,
        name: "Camilo Suarez",
        rol: "Presidente",
        image: camiloSuarez,
        linkedin: 'https://www.linkedin.com/in/camilo-su%C3%A1rez-venegas-a703b91a7/',
        instagram: 'https://www.instagram.com/_suarezcamilo/reels/'
    },
    {
        id: 3,
        name: "Alejandro Celis",
        rol: "Junta Directiva",
        image: alejandroCelis,
        linkedin: 'https://www.linkedin.com/in/alejandro-celis-paez-b59374170/',
        instagram: 'https://www.instagram.com/celisalej093/'
    },
    {
        id: 4,
        name: "Fabian Delgado",
        rol: "Junta Directiva",
        image: fabianDelgado,
        linkedin: 'https://www.linkedin.com/in/fabiandel89/',
        instagram: 'https://www.instagram.com/fabitcoiner/'
    },
    {
        id: 5,
        name: "Juan Restrepo",
        rol: "Junta Directiva",
        image: juanRestrepo,
        linkedin: null,
        instagram: null
    },
    {
        id: 5,
        name: "Fitzgerald Castaño",
        rol: "Junta Directiva",
        image: fitzgeraldCastano,
        linkedin: null,
        instagram: null
    },
    {
        id: 7,
        name: "Felipe Mendoza",
        rol: "Junta Directiva",
        image: felipeMendoza,
        linkedin: null,
        instagram: null
    },
    {
        id: 8,
        name: "Cesar Lopez",
        rol: "Junta Directiva",
        image: cesarLopez,
        linkedin: null,
        instagram: null
    },
    {
        id: 9,
        name: "Brayan Giraldo",
        rol: "Abogado",
        image: brayangiraldo,
        linkedin: null,
        instagram: null
    },
    {
        id: 6,
        name: "Esteban Aragon",
        rol: "Software Engineer",
        image: estebanAragon,
        linkedin: 'https://www.linkedin.com/in/juan-esteban-aragon-montealegre/',
        instagram: 'https://www.instagram.com/aragonestebam/'
    },
]