import React from 'react'
import '../../CommunityMember/styles.css'

const OurMissionCard = ({title, description, icon}) => {
    return (
        <div className='flip-card h-[8em] w-full md:h-[15em] md:w-[22em] bg-transparent inline-block mt-6 md:m-3 whitespace-normal cursor-pointer'>
            <div className='flip-card-inner h-full w-full relative'>
                <div className='flip-card-front h-full w-full absolute rounded-xl flex flex-col justify-center items-center'>
                    <span className='text-white text-md md:text-xl font-bold mx-8'>{title}</span>
                    <span className='text-4xl font-bold mt-4'>{icon}</span>
                </div>
                <div className='flip-card-back h-full w-full absolute rounded-xl flex justify-center items-center p-3'>
                    <span className='text-white text-sm lg:text-xs xl:text-sm'>{description}</span>
                </div>
            </div>
        </div>
    )
}

export default OurMissionCard