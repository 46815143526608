import suarezVenegasAbogadosLogo from '../assets/partners/suarez_venegas_abogados.png'
import bitsionarysLogo from '../assets/partners/bitsionarys.png'
import criptoLatinFestLogo from '../assets/partners/cripto_latin_fest.png'
import mentoriaCriptofinanzasLogo from '../assets/partners/mentoria_criptofinanzas.png'
import mindLogo from '../assets/partners/mind.png'
import criptonacion from '../assets/partners/criptonacion.png'
import mined from '../assets/partners/mined.png'
import principia from '../assets/partners/principia.png'
import torres from '../assets/partners/torres.png'
import trokera from '../assets/partners/trokera.png'

export const PARTNERS = [
    {
        id: 1,
        image: suarezVenegasAbogadosLogo,
        url: 'https://suarezvabogados.com/'
    },
    {
        id: 2,
        image: bitsionarysLogo,
        url: 'https://bitsionarys.com/'
    },
    {
        id: 3,
        image: criptoLatinFestLogo,
        url: 'https://criptolatinfest.com/'
    },
    {
        id: 5,
        image: mindLogo,
        url: 'https://mindworld.io/'
    },
    {
        id: 9,
        image: criptonacion,
        url: 'https://www.instagram.com/criptonacion/'
    },
    {
        id: 10,
        image: mined,
        url: 'https://www.instagram.com/mined.manizales/'
    },
    {
        id: 12,
        image: torres,
        url: 'https://torres.legal/'
    },
    {
        id: 13,
        image: trokera,
        url: 'https://www.trokera.com/'
    },
    {
        id: 7,
        image: mentoriaCriptofinanzasLogo,
        url: ''
    },
    {
        id: 11,
        image: principia,
        url: ''
    }
]