export const OBJECTIVES = [
    {
        id: 1,
        title: "Democratizar el Acceso",
        description: "Facilitar la adopción y comprensión de tecnologías blockchain, criptomonedas y economía descentralizada, asegurando que estén al alcance de todos, independientemente de su nivel socioeconómico.",
        icon: "📖"
    },
    {
        id: 2,
        title: "Construir una Comunidad Inclusiva",
        description: "Fomentar un ambiente de colaboración donde emprendedores, desarrolladores y usuarios puedan compartir conocimientos, experiencias y recursos para impulsar la innovación y el crecimiento conjunto.",
        icon: "🫱🏻‍🫲🏾"
    },
    {
        id: 3,
        title: "Desarrollar Soluciones Locales",
        description: "Apoyar el desarrollo de soluciones blockchain adaptadas a las necesidades específicas del campo colombiano, generando un impacto positivo en la economía local y mejorando la vida de las comunidades menos favorecidas.",
        icon: "🚀"
    },
    {
        id: 4,
        title: "Ofrecer Alternativas Financieras Eficientes",
        description: "Trabajar para establecer canales de transacción más eficientes y económicos, brindando a los menos favorecidos la oportunidad de participar activamente en la economía digital y acceder a servicios financieros de manera más inclusiva.",
        icon: "🏦"
    },
    {
        id: 5,
        title: "Eradicar Estafas y Pirámides en Criptomonedas",
        description: "A través de programas educativos y de concienciación, trabajar incansablemente para prevenir y eliminar estafas relacionadas con criptomonedas y esquemas piramidales.",
        icon: "🛕"
    }
]