import React, { useEffect, useState } from 'react'
import { storage } from '../../config/init-firebase'
import { ref, getDownloadURL } from "firebase/storage";

const PolicyPrivacy = () => {

  const [urlPdf, setUrlPdf] = useState(null)

  useEffect(() => {
    getDownloadURL(ref(storage, 'politica-datos.pdf'))
      .then((url) => {
        setUrlPdf(url)
      })
      .catch((error) => {
        console.error(error, 'error')
      });
  }, [])

  if (urlPdf) {
    return (
      <div className='flex items-center flex-col my-12'>
        <span className='text-white font-bold text-3xl text-center'>
          Política y privacidad de datos
        </span>
        <iframe
          title='policy'
          className='mt-12 w-5/6 mx-12 h-screen'
          src={urlPdf}>
  
        </iframe>
      </div>
    )
  } else {
    return <div/>
  }
}

export default PolicyPrivacy
