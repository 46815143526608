import React from 'react'
import illustrationOurMission from '../../assets/illustration-our-mission.png'
import OurMissionCard from './components/OurMissionCard'
import { OBJECTIVES } from '../../constants/objectives'

const OurMission = () => {
  return (
    <div className='text-white mb-10 px-8 lg:px-20 xl:px-40'>
      <div className='grid xs:grid-rows-2 md:grid-cols-2 gap-2 mt-8'>
        <div className='flex justify-center flex-col text-center md:text-left'>
          <span className='text-6xl lg:text-8xl font-bold'>Nuestra Misión</span>
          <span className='text-xl mt-4'>Construyendo el Futuro Descentralizado, Un Bloque a la Vez.</span>
        </div>
        <div className='flex items-center justify-center'>
          <img src={illustrationOurMission} alt="our-mission" className='w-5/6' />
        </div>
      </div>
      <div className='grid xs:grid-rows-2 md:grid-cols-2 gap-16 mt-16'>
        <div className='text-center'>
          <span className='text-4xl font-bold'>Misión</span>
          <p className='text-xl text-justify mt-8'>Representar, educar y apoyar a la comunidad de emprendedores y usuarios de tecnologías blockchain en Latam. Buscamos construir un ambiente incluyente para el sector rural, fomentando el desarrollo de soluciones innovadoras en economía descentralizada y proporcionando alternativas financieras accesibles para los menos favorecidos.</p>
        </div>
        <div className='text-center'>
          <span className='text-4xl font-bold'>Visión</span>
          <p className='text-xl text-justify mt-8'>Ser la principal asociación en América Latina que empodera a emprendedores y usuarios de nuevas tecnologías en economía digital y criptomonedas, liderando la democratización del acceso a formas descentralizadas de economía y financiación comunitaria.</p>
        </div>
      </div>
      <div className='mt-24'>
        <span className='text-4xl font-bold block m-auto text-center'>Objetivos</span>
        <div className='flex flex-col lg:flex-row md:items-center justify-center md:mt-8'>
          {
            OBJECTIVES.map((item) => (
              <OurMissionCard
                key={item.id}
                title={item.title}
                description={item.description}
                icon={item.icon} />
            ))
          }
        </div>
      </div>

    </div>
  )
}

export default OurMission
